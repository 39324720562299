exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acerca-de-nosotros-tsx": () => import("./../../../src/pages/acerca-de-nosotros.tsx" /* webpackChunkName: "component---src-pages-acerca-de-nosotros-tsx" */),
  "component---src-pages-aspectos-operativos-tsx": () => import("./../../../src/pages/aspectos-operativos.tsx" /* webpackChunkName: "component---src-pages-aspectos-operativos-tsx" */),
  "component---src-pages-crear-demo-tsx": () => import("./../../../src/pages/crear-demo.tsx" /* webpackChunkName: "component---src-pages-crear-demo-tsx" */),
  "component---src-pages-depositos-tsx": () => import("./../../../src/pages/depositos.tsx" /* webpackChunkName: "component---src-pages-depositos-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-por-que-capitaria-tsx": () => import("./../../../src/pages/por-que-capitaria.tsx" /* webpackChunkName: "component---src-pages-por-que-capitaria-tsx" */),
  "component---src-pages-tipos-de-cuentas-tsx": () => import("./../../../src/pages/tipos-de-cuentas.tsx" /* webpackChunkName: "component---src-pages-tipos-de-cuentas-tsx" */),
  "component---src-pages-trabaja-con-nosotros-tsx": () => import("./../../../src/pages/trabaja-con-nosotros.tsx" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-tsx" */)
}

